import React from 'react';
import './App.css';
import { Link } from 'react-scroll';
import ContactForm from './contact';

const Logo = () => {
  return (
    <div className="logo-section">
      <img src="/logo.jpg" alt="Logo" className="navbar-logo" /> 
    </div>
  );
};



const Navbar = () => {
  return (
    <div className="navbar">
      <Link to="home" smooth={true} duration={1000}>Sobre a Melhor Momento</Link>
      <Link to="Servicos" smooth={true} duration={1000}>Serviços</Link>
      <Link to="equipamentos" smooth={true} duration={1000}>Máquinas & Equipamentos</Link>
      <Link to="contato" smooth={true} duration={1000}>Contato</Link>
    </div>
  );
};

const Solucoes = ({ items }) => {
    return (
        <div className="solucoes">
            {items.map((item, index) => <SolucaoItem key={index} {...item} />)}
        </div>
    );
};

  
const HeroSection = () => {

    const items = [
        {
            src: 'item1.png',
            alt: 'item 1',
            title: 'Alimentos, Bebidas & Conveniências',
            description: 'As máquinas dispensadoras de bebidas, alimentos e itens de conveniência revolucionaram a maneira como consumimos produtos no dia a dia. Compactas e eficientes, essas máquinas são verdadeiras lojas automáticas que dispensam a necessidade de um vendedor humano, proporcionando um serviço conveniente e acessível 24 horas por dia. Elas oferecem uma variedade de produtos, desde bebidas refrescantes e lanches rápidos até itens essenciais de conveniência como lenços, desodorantes e até medicamentos. Seja em locais públicos como aeroportos e estações de trem, ou em locais privados como escritórios e academias, as máquinas de venda automática são uma solução moderna que atende às necessidades urgentes dos consumidores de forma prática e eficiente.'
        },
        {
            src: 'item2.png',
            alt: 'item 2',
            title: 'Conforto & Comodidade',
            description: 'As máquinas de conforto e comodidade, como poltronas massageadoras e cápsulas de repouso, trazem uma série de benefícios que contribuem significativamente para a qualidade de vida e o bem-estar das pessoas. Elas proporcionam relaxamento, alívio do estresse e podem até auxiliar no tratamento de determinadas condições de saúde. As poltronas massageadoras, por exemplo, oferecem uma forma conveniente e personalizável de massagem, ajudando a aliviar a tensão muscular, melhorar a circulação sanguínea e promover a liberação de endorfinas, que são neurotransmissores que promovem uma sensação de felicidade. Já as cápsulas de repouso, podem fornecer um ambiente tranquilo e isolado, favorecendo um descanso mais profundo e restaurador, auxiliando na recuperação do cansaço e contribuindo para uma melhor saúde mental. Assim, esses dispositivos representam uma maneira eficaz de integrar cuidados com a saúde e o bem-estar no dia a dia corrido e estressante das pessoas.'
        },
        {
            src: 'item3.png',
            alt: 'item 3',
            title: 'Recreação',
            description: 'Os recursos recreativos como fliperamas, mesas de bilhar e de ping-pong, entre outros, quando integrados em espaços de trabalho, condomínios e outros ambientes, possuem inúmeros benefícios. Primeiramente, eles contribuem para a melhoria da convivência entre os usuários desses espaços, fomentando a socialização e a integração de equipes ou comunidades. Além disso, atividades recreativas podem ajudar a reduzir o estresse, promovendo relaxamento e bem-estar mental. Essas atividades também estimulam o pensamento estratégico e o desenvolvimento de habilidades motoras finas. A presença desses recursos pode tornar o ambiente mais atraente e confortável, contribuindo para a retenção e satisfação dos funcionários em um escritório, ou para a qualidade de vida dos moradores em um condomínio. Finalmente, a pausa para recreação pode aumentar a produtividade e a criatividade, pois ajuda a quebrar a rotina e a recarregar as energias.'
        }        
    ];

    return (
      <div id="home" className="hero">
        <h1>Sobre a Melhor Momento!</h1>
        <p>A Melhor Momento é uma empresa que transforma seus espaços em verdadeiros oásis de conveniência e conforto. Nós atuamos nos mais variados ambientes: corporativos, comerciais e residenciais, sempre com um objetivo em mente - melhorar a qualidade de vida daqueles que circulam por esses locais.</p>
        <p>Com um catálogo de soluções diversificadas, fornecemos desde elegantes máquinas de alimentos, projetadas para nutrir o corpo e o espírito, até sofisticadas poltronas massageadoras, perfeitas para relaxar após um longo dia. E para tornar os momentos de descontração ainda mais prazerosos, oferecemos uma variedade de entretenimento, incluindo mesas de bilhar, arcades e karaokê. Cada detalhe é pensado para proporcionar bem-estar, diversão e comodidade.</p>
        <p>Nossos serviços flexíveis podem ser contratados para eventos específicos ou de forma contínua. Imagine, por exemplo, uma máquina de venda automática de bebidas em seu próximo evento esportivo, ou uma máquina de venda de revistas em uma conferência de negócios. Ao mesmo tempo, temos um compromisso duradouro com nossos parceiros, oferecendo contratos contínuos de instalação e gerenciamento de soluções de conveniência para escritórios, condomínios, academias e muito mais.</p>
        <p>Na Melhor Momento, acreditamos que cada instante deve ser aproveitado ao máximo. Assim, convidamos você a descobrir como podemos melhorar seus ambientes e proporcionar experiências incríveis para todos. Junte-se a nós e transforme cada momento em uma oportunidade para desfrutar da melhor qualidade de vida!</p>     
        <Solucoes items={items} />
      </div>
    );
  };

  const SolucaoItem = ({src, alt, title, description}) => {
    return (
        <div className="solucao-item">
            <img src={src} alt={alt} />
            <h3>{title}</h3>
            <p>{description}</p>
        </div>
    )
};  

const Servicos = () => {
  return (
    <div id="Servicos" className="about-us">
      <h2>Serviços</h2>
      <p>A Melhor Momento é a sua chave para uma experiência superior de conveniência, conforto e recreação. Nós oferecemos uma gama diversificada de soluções, desde vending machines até poltronas massageadoras, cápsulas de repouso, mesas de bilhar, arcades fliperamas, e muito mais! Adaptamos nossos serviços a variadas modalidades contratuais, garantindo a máxima flexibilidade para atender às suas necessidades.</p>
        <p>Encontre a solução perfeita para o seu ambiente:</p>
        <p>Locação fixa para locais com grande fluxo de pessoas: Transforme seu espaço em uma fonte de bem-estar e uma máquina de renda. Além de melhorar a experiência dos visitantes, nossas máquinas de venda de alimentos, bebidas e outros itens de conveniência também podem gerar lucro para a sua empresa. Nós fornecemos nossos equipamentos em regime de comodato, fazendo a instalação, manutenção e reabastecimento durante o tempo contratual, e compartilhando os lucros obtidos. Locais ideais para este serviço incluem hospitais, hipermercados, grandes escritórios, academias de grande porte, centros universitários, e grandes condomínios residenciais.</p>
        <p>Locação fixa para locais com baixo fluxo de pessoas: Para locais mais tranquilos, oferecemos o mesmo regime de comodato, garantindo a instalação, manutenção e reabastecimento de nossos recursos de bem-estar. Enquanto esta opção pode não gerar lucro diretamente, ela agrega valor ao seu espaço e pode exigir uma taxa mensal de locação e gerenciamento. Este serviço é ideal para clínicas médicas, pequenos condomínios, academias, e escritórios de médio ou pequeno porte.</p>
        <p>Locações temporárias ou itinerantes: Para eventos ou espaços de curta duração, temos contratos flexíveis e customizáveis. Podemos oferecer a locação dos recursos para serem gerenciados pelo próprio contratante ou fornecer esse serviço nós mesmos. Por conta dos custos associados à logística e instalação, este serviço pode não resultar em lucro direto, mas sem dúvida agrega valor e proporciona uma experiência única para os usuários.</p>
    </div>
  );
};

const Contato = () => {
    return (
      <div id="contato" className="contact-us">
        <h2>Contato</h2>
        <p>Gerente Comercial: Jonathan S. Santos</p>
        <p>
          Telefone & WhatsApp: 
          <a href="https://wa.me/5511999897579?text=Olá,%20estou%20interessado%20em%20seus%20serviços." target="_blank" rel="noopener noreferrer">
            (11) 99989-7579
          </a>
        </p>
        <p>Atendemos São Paulo, Capital e Grande São Paulo.</p>
        <h3>Se preferir, envie-nos uma mensagem pelo formulário:</h3>
        <div className="contact-form-container">
            <ContactForm/>
        </div>
      </div>
    );
};

  

const Equipamentos = () => {
    const items = [
        {
            src: 'snacks.png',
            alt: 'item 1',
            title: 'Flex Compacta: Alimentos & Bebidas',
            description: 'Ideal para espaços pequenos, a Flex Compacta pode ser abastecida com mais de 200 itens de snacks, bebidas ou demais itens de conveniência.'
        },
        {
            src: 'combomix.png',
            alt: 'item 2',
            title: 'Combo Mix, Alimentos & Bebidas (Grande Capacidade)',
            description: 'Perfeita para espaços movimentados com grande fluxo de pessoas. Capacidade de até 600 produtos entre alimentos, bebidas e diversos.'
        },
        {
            src: 'congelados.png',
            alt: 'item 3',
            title: 'Combo Frost, Congelados',
            description: 'Com elevador, temperatura estável e baixa para produtos congelados.. Excelente para venda de sorvetes em pote, massas congeladas, lanches congelados, etc..'
        }
    ];

    const segundalinha = [
        {
            src: 'micromarket.png',
            alt: 'item 4',
            title: 'Micro Mercado',
            description: 'Solução perfeita para a instalação de um micro mercado. Capacidade de até 819 produtos, perfeita para condomínios, postos de gasolina, etc...'
        },
        {
            src: 'wine.png',
            alt: 'item 5',
            title: 'Adega Inteligente',
            description: 'A adega inteligente traz a conveniência de uma loja de bebidas engarrafadas para  diversos espaços possíveis. Capacidade de até 54 garrafas.'
        }
    ];

    const terceiralinha = [
        {
            src: 'poltrona.webp',
            alt: 'item 6',
            title: 'Poltrona Lux-Orange',
            description: 'A Poltrona Lux-Orange mescla sofisticação com praticidade. Esta poltrona luxuosa de cor laranja proporciona uma gama de massagens personalizáveis para alívio de tensões e aumento do bem-estar. Seu design ergonômico e acolchoamento garantem um conforto superior. Com ela, cada momento de relaxamento se transforma em uma autêntica experiência de spa exclusivo.'
        },
        {
            src: 'classic-table.png',
            alt: 'item 7',
            title: 'Mesa de Bilhar Oficial',
            description: 'A Mesa de Sinuca Oficial é um equipamento de alta qualidade, projetado com atenção meticulosa aos detalhes para proporcionar a melhor experiência de jogo possível. Possui uma borda rebaixada que facilita a jogada, aprimorando o controle sobre as bolas. Sua superfície é equipada com uma função anti-derrapante, prevenindo deslizamentos indesejados e garantindo a precisão do jogo. A mesa conta ainda com um sistema de roldanas, que permite o deslocamento suave e fácil do móvel, oferecendo maior conveniência e adaptabilidade ao espaço.'
        },
        {
            src: 'arcade.png',
            alt: 'item 8',
            title: 'Arcade (Fliperama)',
            description: 'Os Arcades trazem entretenimento para todas as idades, elevando a atmosfera de qualquer ambiente. Em espaços comerciais, atraem uma variedade de clientes e incentivam uma estadia mais longa, aumentando as vendas. Em residências, proporcionam um local de socialização único. Em eventos, promovem interação e engajamento, tornando qualquer ocasião mais memorável.'
        }
    ];

    return (
      <div id="equipamentos" className="contact-us">
        <h2>Máquinas & Equipamentos</h2>
        <Solucoes items={items} />
        <Solucoes items={segundalinha} />
        <Solucoes items={terceiralinha} />
      </div>
    );
  };

  

const Footer = () => {
    return (
      <div className="footer">
        <p>© 2023 Melhor Momento</p>
      </div>
    );
  };
 
  
  function App() {
    return (
      <div className="App">
        <Logo/>
        <Navbar />
        <HeroSection />
        <Servicos />     
        <Equipamentos />     
        <Contato />
        <Footer />
      </div>
    );
  }
  

export default App;
