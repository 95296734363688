import { useState } from 'react';
import InputMask from 'react-input-mask';
import emailjs from 'emailjs-com';

const ContactForm = () => {
    const [state, setState] = useState({
      nomeCompleto: '',
      email: '',
      telefone: '',
      mensagem: '',
    });

    const [successMessage, setSuccessMessage] = useState('');

    const handleInputChange = (event) => {
      setState({
        ...state,
        [event.target.name]: event.target.value,
      });
    };

    const handleSubmit = (event) => {
      event.preventDefault();

      const regex = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/;
      if (!regex.test(state.email)) {
        alert('Formato de email inválido');
        return;
      }

      // Send an email via Email.js
      emailjs.send('service_ir0qhvl', 'template_ymky0vs', state, '9YnnZ8OySYdzNL2Ba')
        .then((result) => {
          console.log(result.text);
          setSuccessMessage('Formulário enviado com sucesso');
        }, (error) => {
          console.log(error.text);
        });
    };

    return (
      <form className="contactForm" name="contact" onSubmit={handleSubmit}>
        <p>
          <label>
            Nome Completo
            <input type="text" name="nomeCompleto" value={state.nomeCompleto} onChange={handleInputChange} required />
          </label>
        </p>
        <p>
          <label>
            E-mail
            <input type="email" name="email" value={state.email} onChange={handleInputChange} required />
          </label>
        </p>
        <p>
          <label>
            Telefone
            <InputMask mask="(99) 99999-9999" type="text" name="telefone" value={state.telefone} onChange={handleInputChange} required />
          </label>
        </p>
        <p>
          <label>
            Mensagem
            <textarea name="mensagem" value={state.mensagem} onChange={handleInputChange} required />
          </label>
        </p>
        <p>
          <button type="submit">Enviar</button>
        </p>
        {successMessage && <p>{successMessage}</p>}
      </form>
    );
}

export default ContactForm;
